import React, {useEffect, useMemo, useRef, useState} from "react"
import lottie from "lottie-web"
import Content from "../core/Content"
import Row from "../layout/Row"
import Cell from "../layout/Cell"
import "./Welcome.css"
import logoAnimation from "../files/features/Kaller_logo.json"


import apple from "../assets/welcome/apple.svg"
import android from "../assets/welcome/android.svg"
import WelcomeVideo from "./WelcomeVideo"

const Welcome = (props) => {
    const [placeholder, hidePlaceholder] = useState("")
    const [logoIsVisible, setLogoVisibility] = useState(true)

    const logoContainer = useRef(null)
    const logoRef = useRef(null)
    const block = new Content()
    const [lang, setLang] = useState(localStorage.getItem('SELECTED_LANGUAGE'))

    useEffect(() => {
        setLang(localStorage.getItem('SELECTED_LANGUAGE'))
    }, [localStorage.getItem('SELECTED_LANGUAGE')])

    const animationDataAsString = useMemo(() => JSON.stringify(logoAnimation), [logoAnimation]);


    const logoOptions = useMemo(() =>{
        return {
            renderer: 'canvas',
            loop: true,
            autoplay: true,
            animationData: JSON.parse(animationDataAsString),
            container: logoContainer.current,
        }
    }, [animationDataAsString])




    useEffect(() => {
        const logoObserver = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setLogoVisibility(true)
            } else {
                setLogoVisibility(false)
            }
        }, {
            rootMargin: '-88px 0px -88px 0px'
        })

     
        logoRef.current = lottie.loadAnimation({
            ...logoOptions,
            container: logoContainer.current,
        })
        logoObserver.observe(logoContainer.current)



        return () => {
            logoObserver.disconnect()
            if (logoRef.current) {
                logoRef.current.destroy()
            }
        }
    }, [logoOptions, logoIsVisible, logoContainer])

    return (
        <section>
            <Row className="welcome-container">
                <Cell className="-nine">
                    {props.title && (
                        <Row>
                            <Cell className="-eight">
                                <div
                                    className={`welcome-kaller ${lang === 'ar' || lang === 'he' ? 'welcome-kaller-arabic' : ''}`}
                                >
                                    <div
                                        className="welcome-logo"
                                        ref={logoContainer}
                                        key="kaller-logo"
                                    >
                                        <div
                                            className={"welcome-logo-placeholder " + placeholder}
                                        />
                                    </div>
                                    <div>
                                        <div className="welcome-name">{props.title}</div>
                                        <div className={`welcome-tagline ${localStorage.getItem('SELECTED_LANGUAGE') === 'ru' ? 'welcome-tagline-ru' : ''}`}>{props.tagline}</div>
                                    </div>
                                </div>
                                <div
                                    className="welcome-text"
                                    dangerouslySetInnerHTML={{__html: props.text}}
                                />
                                <div className="welcome-links"
                                >
                                    <div className="welcome-link">
                                        <img src={apple} alt="Apple logo"/>
                                        <a
                                            href={props.apple.url}
                                            dangerouslySetInnerHTML={{
                                                __html: block.boldMDtoHTML(props.apple.title),
                                            }}
                                        />
                                    </div>
                                    <div className="welcome-link welcome-android">
                                        <img src={android} alt="Android logo"/>
                                        <a
                                            href={props.android.url}
                                            dangerouslySetInnerHTML={{
                                                __html: block.boldMDtoHTML(props.android.title),
                                            }}
                                        />
                                    </div>
                                </div>
                            </Cell>
                            <Cell className="-four welcome-simulator">
                                    <WelcomeVideo />
                            </Cell>
                        </Row>
                    )}
                </Cell>
            </Row>
        </section>
    )
}

export default Welcome
