import React from "react";
import './FaqCategory.css';

const FaqCategory = (props) => {
    const category = props.category;
    return (category &&
        <div className="faq-category-section">
            <h2 id={`category${category.tid}`}>
                {category.name}
            </h2>
            {category.questions.map((question, index) => {
                return (
                    <div id={`question${question.id}`}
                        key={`question${question.id}`}
                        className="faq-category-question"
                    >
                        <h3>Q: {question.title}</h3>
                        <p className="faq-category-body"
                            dangerouslySetInnerHTML={{ __html: question.body }}
                        >
                        </p>
                    </div>
                );
            })}
        </div>
    );
}
export default FaqCategory;