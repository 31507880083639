import React from "react";
import './Row.css';

const Row = (props) => {
    const classes = props.className ? props.className : '';
    return (
        <div className = {`row ${classes}`}>
            {props.children}
        </div>
    );
}

export default Row;