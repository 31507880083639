import React, { useState, useRef } from "react";
import ReCAPTCHA from 'react-google-recaptcha';
import DOMPurify from 'dompurify';
import Content from '../core/Content';
import configData from '../config.json';
import './BetaRequest.css';

const BetaRequest = (props) => {
    const form = props.form;
    const msg = props.msg;

    const [firstName, setFirstName] = useState("");
    const [firstNameStatus, setFirstNameStatus] = useState("");
    const [lastName, setLastName] = useState("");
    const [lastNameStatus, setLastNameStatus] = useState("");
    const [email, setEmail] = useState("");
    const [emailStatus, setEmailStatus] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);

    const captchaRef = useRef(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let errorMsg = false;
        if (!firstName) {
            setFirstNameStatus('-error');
            errorMsg = form.firstName.name;
        }
        if (!lastName) {
            setLastNameStatus('-error');
            errorMsg = errorMsg
                ? errorMsg + ", " + form.lastName.name
                : form.lastName.name;
        }
        if (!email) {
            setEmailStatus('-error');
            errorMsg = errorMsg
                ? errorMsg + ", " + form.email.name
                : form.email.name;
        }
        if (errorMsg) setError(msg.emptyField + " " + errorMsg);
        if (!email || !lastName || !email) return;

        if (!(/\S+@\S+\.\S+/.test(email))) {
            setError(msg.emailErr);
            setEmailStatus('-error');
            return;
        }

        let token = captchaRef.current.getValue();
        if (!token) {
            setError(msg.checkCaptcha);
            return;
        }

        let valid_token = await verifyToken(token);
        if (valid_token.success) {
            setSuccess(msg.successMsg);
        } else {
            setError(msg.captchaErr);
        }
    };

    const verifyToken = async (token) => {
        const content = new Content();
        const path = (configData.get.verifyToken 
            + "?firstName=" + DOMPurify.sanitize(firstName.slice(0,31))
            + "&lastName=" + DOMPurify.sanitize(lastName.slice(0,31))
            + "&email=" + DOMPurify.sanitize(email.slice(0,31))
            + "&msg=" + DOMPurify.sanitize(message.slice(0,255))
            + "&os=" + DOMPurify.sanitize(props.os.slice(0,7))
            + "&lang=" + props.lang
            + "&t=" + token
        );
        const data = await content.getToken(path);
        return data;
    }

    return (<div className="-narrow-column">
        {!success && <form className="beta-request-form" onSubmit={handleSubmit}>
            <label className={"beta-request-field " + firstNameStatus}>
                {form.firstName.name}
                <input
                    className="beta-request-input"
                    type="text"
                    placeholder={form.firstName.placeholder}
                    onChange={(e) => {
                        setFirstName(e.target.value);
                        setFirstNameStatus("");
                    }}
                />
            </label>
            <label className={"beta-request-field " + lastNameStatus}>
                {form.lastName.name}
                <input
                    className="beta-request-input"
                    type="text"
                    placeholder={form.lastName.placeholder}
                    onChange={(e) => {
                        setLastName(e.target.value);
                        setLastNameStatus("");
                    }}
                />
            </label>
            <label className={"beta-request-field " + emailStatus}>
                {form.email.name}
                <input
                    className="beta-request-input"
                    type={"email " + emailStatus}
                    placeholder={form.email.placeholder}
                    onChange={(e) => {
                        setEmail(e.target.value)
                        setEmailStatus("");
                    }}
                />
            </label>
            <label className="beta-request-field">
                {form.comment.name}
                <textarea
                    className="beta-request-input beta-request-textfield"
                    onChange={(e) => setMessage(e.target.value)}
                />
            </label>
            {error && <p className='-error -margin-8'>{error}</p>}
            <ReCAPTCHA
                className="beta-request-captcha"
                sitekey={process.env.REACT_APP_SITE_KEY}
                ref={captchaRef}
            />
            <input
                className="beta-request-field beta-request-button"
                type="submit"
                value={form.submit.name}
            />
        </form>}
        {success && <>
            <h2 className='-margin-16'>{msg.successTitle}</h2>
            <p className='-margin-16'><b>{success}</b></p>
        </>}
    </div>);
}

export default BetaRequest;