import React, { useEffect, useState, useRef } from "react";
import Content from "../core/Content";
import "./Language.css";
import globe from "../assets/navigation/globe.svg";
import arrowDown from "../assets/navigation/arrow-down-10.svg";

const Language = (props) => {
  const content = new Content();
  const dropDown = useRef(null);
  const button = useRef(null);
  const languages = props.languages;
  const language = content.language;
  const [selectedLanguage, setLanguage] = useState(language);
  const [isOpen, setIsOpen] = useState(false);
  const [rotation, setRotation] = useState("");


  const toggleVisibility = () => {
    const degree = isOpen ? "" : "language-arrow-rotate";
    setRotation(degree);
    setIsOpen(!isOpen);
  };

  const selectLanguage = (lang) => {
    content.language = lang;
    setLanguage(lang);
  };

  useEffect(() => {
    const _content = new Content();
    const lang = _content.language;
    setLanguage(lang);
    const hidePullDown = (event) => {
      const menu = dropDown.current;
      const _button = button.current;
      const node = event.target;
      if (!menu) return;
      if (menu.contains(node)) return;
      if (_button.contains(node)) return;
      setRotation("");
      setIsOpen(false);
    };
    document.addEventListener("click", hidePullDown);
    return window.removeEventListener("click", hidePullDown);
  }, [dropDown, button, setIsOpen, setRotation]);

  return (
      <li className="language-item">
        {languages.length > 1 ? (
            <button
                className="language-button"
                onClick={toggleVisibility}
                ref={button}
            >
              <img src={globe} alt="Select language" className="language-globe" style={localStorage.getItem('SELECTED_LANGUAGE') === 'ar' || localStorage.getItem('SELECTED_LANGUAGE') === 'he' ? {marginLeft: '5px'} : {}}/>
              <span className="selected-language">{localStorage.getItem('SELECTED_LANGUAGE')}</span>
              <img
                  src={arrowDown}
                  alt="drop down"
                  className={"language-arrow " + rotation}

              />
            </button>
        ) : (
            languages[0] && <button className="language-single-button">
              <img src={globe} alt="Select language" className="language-globe" />
              <span className="selected-language">{languages[0].short}</span>
            </button>
        )}
        {isOpen ? (
            <ul className="language-available" ref={dropDown} style={localStorage.getItem('SELECTED_LANGUAGE') === 'ar' || localStorage.getItem('SELECTED_LANGUAGE') === 'he'  ? {left: '0'} : {}}>
              {languages[0] &&
                  languages.map((language) => (
                      language.name === 'English' && <li
                          className={
                              "language-available-item " +
                              (language.short === selectedLanguage
                                  ? "language-selected"
                                  : "")
                          }
                          key={language.short}
                          short={language.short}
                          onClick={() => selectLanguage(language.short)}
                      >
                        {language.name}
                      </li>
                  ))}
              <li
                  className={
                      "language-available-item "
                  }
                  key={language.short}
              >
                {'Coming soon...'}
              </li>
            </ul>
        ) : (
            ""
        )}
      </li>
  );
};

export default Language;
