import React, {useEffect, useRef, useState} from "react"
import { useLocation } from "react-router-dom";
import Content from "../core/Content";
import Article from "../components/Article";
import BetaRequest from "../components/BetaRequest";
import configData from "../config.json";

const Beta = () => {
  const [request, setRequest] = useState(false);
  const [lang, setLang] = useState("");
  const location = useLocation();
  const os = new URLSearchParams(location.search).get("d");
  const pageContent = new Content();
  const [title, setTitle] = useState(pageContent.defaultTitle);
  const parent = useRef(null)

  useEffect(() => {
    const content = new Content();
    setLang(content.language);
    (async () => {
      const response = await content.getBeta();
      setTitle(response.title);
      setRequest(response);
    })();
  }, []);


  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <>
      <article ref={parent}>
        {request && (
          <Article
            title={request.title}
            body={request.body}
            banner={false}
            z={1}
            parent={parent}
          />
        )}
      </article>
      {request && (
        <BetaRequest
          form={request.form}
          reCaptchaKey={configData.reCaptchaSiteKey}
          os={os}
          lang={lang}
          msg={request.messages}
        />
      )}
    </>
  );
};

export default Beta;
