import React from "react";
import './Cell.css';

class Cell extends React.Component {
    render() {
        return (
            <div className={this.props.className + " cell"}>
                {this.props.children}
            </div>
        );
    }
}

export default Cell;