import React, { useState, useEffect, useRef } from "react";
import Content from "../core/Content";
import Article from "../components/Article";
import AdditionalFeed from "../components/AdditionalFeed";
import Museum from "../components/Museum";
import JoinUsBlock from "../components/JoinUsBlock";
import playButtonSvg from "../assets/play.svg";
import "./About.css";

const About = () => {
  const [about, setAbout] = useState(null);
  const [animation, setAnimation] = useState(null);
  const [additionalBlock, setAdditionaBlock] = useState(null);
  const [museum, setMuseum] = useState(null);
  const [joinUs, setJoinUs] = useState(null);
  const articleContainer = useRef(null);
  const pageContent = new Content();
  const isMobile = pageContent.isMobile;
  const isTablet = pageContent.isTablet;
  const defaultTitle = pageContent.defaultTitle;
  const [title, setTitle] = useState(defaultTitle);


  useEffect(() => {
    const content = new Content();
    (async () => {
      const response = await content.getAbout();
      setAbout(response);
      setAnimation(response.animation);
      setTitle(response.title);
    })();
    (async () => {
      const response = await content.getMuseum();
      setMuseum(response);
    })();
    (async () => {
      const response = await content.getJoinUs();
      setJoinUs(response);
    })();
    (async () => {
      const response = await content.getNews();
      setAdditionaBlock(response);
    })();
  }, []);

  useEffect(() => {
    if (!about) return;
    const playVideo = () => {
      const video = document.getElementById("about-office-video");
      var playPromise = video.play();
      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            video.previousSibling.classList.add("about-play-button-zoom-out");
            video.setAttribute("controls", "controls");
            video.play();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };

    const video = document.getElementById("about-office-video");
    if (!video) return;
    const container = video.parentElement;
    const playButton = document.createElement("img");
    playButton.src = playButtonSvg;
    playButton.style.cssText = `
        position: absolute;
        width: 14%;
        opacity: 0.75;
        left: 43%;
        top: 43%;
        margin-top: 0;
        filter: drop-shadow(0 0 20px rgb(0 0 0));
    `;

    container.style.position = "relative";
    container.insertBefore(playButton, video);
    playButton.addEventListener("click", playVideo);

    if (!isMobile) {
      video.setAttribute("playsinline", "true");
    }
    video.addEventListener("click", playVideo);
    video.style.cursor = "pointer";
    video.style.aspectRatio = "1.7778";

    return () => {
      video.removeEventListener("click", playVideo);
      playButton.removeEventListener("click", playVideo);
      playButton.remove();
    };
  }, [about, isMobile]);

  useEffect(() => {
    document.title = defaultTitle + ". " + title;
  }, [title, defaultTitle]);

  return (
    <div className={'aboutContainer'}>
      <article ref={articleContainer}>
        {about && (
          <Article
            title={about.title}
            body={about.body}
            animation={animation}
            parent={articleContainer}
          />
        )}
      </article>
      {additionalBlock && (
        <AdditionalFeed content={additionalBlock} parent={articleContainer} />
      )}
      {joinUs && <JoinUsBlock body={joinUs.body} />}
      {museum && (
        <Museum
          title={pageContent.breakLine(museum.title)}
          galleries={museum.galleries}
        />
      )}
    </div>
  );
};

export default About;
