import React from 'react';
import RowWithTwoNews from './RowWithTwoNews';
import './NewsBlocks.css';

const NewsBlocks = (props) => {
    return (
        <div className="news-blocks-container">
            <h2 className="-center">{props.header}</h2>
            {props.news && props.news.map((row, index) => 
                <RowWithTwoNews cells={row} key={'news-row-' + index} />
            )}
        </div>
    );
}

export default NewsBlocks;