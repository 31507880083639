import React, { useState, useEffect, useRef } from "react";
import {useLocation, useParams} from "react-router-dom"
import Content from "../core/Content";
import Article from "../components/Article";
import AdditionalFeed from "../components/AdditionalFeed";
import NewsBlocks from "../components/NewsBlocks";
import './NewsArticle.css'

const NewsArticle = () => {
  const { alias } = useParams();
  const [content, setContent] = useState(null);
  const [additionalBlock, setAdditionaBlock] = useState(null);
  const [news, setNews] = useState(null);
  const [newsHeader, setNewsHeader] = useState(null);
  const [animation, setAnimation] = useState(null);
  const articleContainer = useRef(null);
  const pageContent = new Content();
  const isMobile = pageContent.isMobile;
  const isTablet = pageContent.isTablet;
  const [title, setTitle] = useState(pageContent.defaultTitle);
  const location = useLocation()

  useEffect(() => {
    const content = new Content();
    (async () => {
      const response = await content.getNewsArticle(alias);
      setContent(response);
      setTitle(
        content.removeBreakLine(response.title)
      );
      setAnimation(response.animation);
    })();
    (async () => {
      const response = await content.getNews();
      setAdditionaBlock(response);
      setNewsHeader(response.header);
      const flatBlocks = response.news.reduce((result, element) => {
        if (element.alias !== alias) {
          element.path = content.newsLink + element.alias;
          result.push(element);
        }
        return result;
      }, []);

      const blocks = content.splitRows(flatBlocks.filter(block => !location.pathname.includes(block.path)));
      setNews(blocks);
    })();
  }, [alias]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <>
      <article ref={articleContainer} className={'newsArticle'}>
        {content && (
          <Article
            title={content.title}
            leed={content.leed}
            banner={content.banner}
            gallery={content.gallery}
            body={content.body}
            animation={isMobile || isTablet ? null : animation}
            parent={articleContainer}
            author={content.author}
            date={content.date}
            sticker={true}
          />
        )}
        {additionalBlock && (
          <AdditionalFeed content={additionalBlock} parent={articleContainer} />
        )}
      </article>
      <section className="-main-column">
        {newsHeader && news && <NewsBlocks header={newsHeader} news={news} />}
      </section>
    </>
  );
};

export default NewsArticle;
