import React from "react";
import { Link } from "react-router-dom";
import Row from '../layout/Row';
import BlogBlock from './BlogBlock';
import './HomePageBlogs.css';


const HomePageBlogs = (props) => {
    const news = props.blogs.news;
    const link = props.blogs.path;
    const query = props.blogs.query;

    return (
        <section className="blog-block-container">
            <h2 className="blog-block-title -center">
                <Link to = {link + query}
                    onClick = {() => {
                        window.scrollTo({top: 0, left: 0});
                    }}
                >
                    {props.blogs.header}
                </Link>
            </h2>
            <Row className="blog-block-row">
                {news[0] && <BlogBlock
                    banner = {news[0].banner ? news[0].banner : false} 
                    title = {news[0].title} 
                    subtitle = {news[0].leed} 
                    path = {props.blogs.path + news[0].alias + query}
                    key = {'blog1'}
                    date = {news[0].date}
                />}
                {news[1] && <BlogBlock
                    banner = {news[1].banner ? news[1].banner : false}  
                    title = {news[1].title} 
                    subtitle = {news[1].leed} 
                    path = {props.blogs.path + news[1].alias + query}
                    key = {'blog2'}
                    date = {news[1].date}

                />}
            </Row>
        </section>
    );
}

export default HomePageBlogs;