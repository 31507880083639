import React from "react"
import {BrowserRouter} from "react-router-dom/"
import Root from "./core/Root"
import Layout from "./layout/Layout"
import Navigation from "./components/Navigation"
import Footer from "./components/Footer"
import FontSelector from "./components/FontSelector"
import FontDirection from "./components/FontDirection"

function App() {

    const location = new URLSearchParams(window.location.href.split('?')[1]).get('l')

    let lang = location ? location : 'en'
    localStorage.setItem('SELECTED_LANGUAGE', lang)

    return (
        <FontSelector>
            <BrowserRouter>
                <FontDirection>
                    <Navigation/>
                    <Layout>
                        <main role="main">
                            <Root/>
                        </main>
                        <Footer/>
                    </Layout>
                </FontDirection>
            </BrowserRouter>
        </FontSelector>

    )
}

export default App
