import React, {useEffect, useState} from "react"
import {NavLink, useLocation} from "react-router-dom"
import Content from "../core/Content"
import Language from "./Language"
import "./Navigation.css"
import ThemeToggle from "./ThemeToggle"
import nav from '../files/text/nav.json'

function Navigation() {
    const itemClass = "navigation-item"
    const [navigationItems, setNavigationItems] = useState({})
    const [languages, setLanguages] = useState([])
    const location = useLocation()
    const [activeUrl, setActiveUrl] = useState(null)

    useEffect(() => {
        const path = location.pathname.split("/")
        setActiveUrl("/" + path[1])
    }, [location])

    useEffect(() => {
        const RouteSections = new Content();
        (async () => {
            // const response = await RouteSections.getNavigation()
            // setNavigationItems(response)
            const json = nav[localStorage.getItem('SELECTED_LANGUAGE')] || nav['en']

            setNavigationItems(json)
        })();
        
        (async () => {
            const response = await RouteSections.getLanguages()
            setLanguages(response)
        })()
    }, [localStorage.getItem('SELECTED_LANGUAGE')])



    return (
        <nav>
            <meta name="viewport" content="width=device-width, initial-scale = 1, shrink-to-fit = no"/>
            <meta name="theme-color" content="#243b55"/>
            <ul className="navigation-list">
                {navigationItems[0] &&
                    navigationItems.map((item, index) => {
                        const url = item.url.split('?')
                        return (
                            <li
                                className={itemClass + (activeUrl === url[0] ? " isActive" : "")}
                                key={"nav_item" + index}
                            >
                                <NavLink to={item.url}>{item.title}</NavLink>
                                <div className="selected-item"/>
                            </li>
                        )
                    })}
                {/*{*/}
                {/*    navigationItems[0] &&*/}
                {/*    // <div style={*/}
                {/*    //     localStorage.getItem('SELECTED_LANGUAGE') === 'ar' || localStorage.getItem('SELECTED_LANGUAGE') === 'he' ?*/}
                {/*    //         {*/}
                {/*    //             justifySelf: 'flex-start-end',*/}
                {/*    //             marginRight: 'auto',*/}
                {/*    //             height: '48px',*/}
                {/*    //             display: 'flex',*/}
                {/*    //             alignItems: 'center',*/}
                {/*    //             marginTop: '2px'*/}
                {/*    //*/}
                {/*    //         } : localStorage.getItem('SELECTED_LANGUAGE') === 'ru' ?*/}
                {/*    //             {*/}
                {/*    //                 justifySelf: 'flex-end',*/}
                {/*    //                 marginLeft: 'auto',*/}
                {/*    //                 height: '48px',*/}
                {/*    //                 display: 'flex',*/}
                {/*    //                 alignItems: 'center',*/}
                {/*    //                 marginTop: '6px',*/}
                {/*    //             }*/}
                {/*    //             :*/}
                {/*    //             {*/}
                {/*    //                 justifySelf: 'flex-end',*/}
                {/*    //                 marginLeft: 'auto',*/}
                {/*    //                 display: 'flex',*/}
                {/*    //                 alignItems: 'center',*/}
                {/*    //                 height: '100%',*/}
                {/*    //             }*/}
                {/*    // }*/}
                {/*    // className={localStorage.getItem('SELECTED_LANGUAGE') !== 'ru' ? "theme-toggle" : 'theme-toggle-ru'}*/}
                {/*    // >*/}
                {/*    // </div>*/}
                {/*    <></>*/}
                {/*}*/}

                <div style={localStorage.getItem('SELECTED_LANGUAGE') === 'ar' || localStorage.getItem('SELECTED_LANGUAGE') === 'he' ? {height: '100%', display: 'flex', alignItems: 'center', justifySelf: 'start', gap: '5px', marginRight:'auto'} : {height: '100%', display: 'flex', alignItems: 'center', justifySelf: 'end', marginLeft: 'auto', gap: '5px'}}>
                    <ThemeToggle/>
                    <Language languages={languages}/>
                </div>
            </ul>
        </nav>
    )
}

export default Navigation
