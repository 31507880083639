import React, {useState, useRef, useLayoutEffect} from 'react';
import Row from '../layout/Row';
import Cell from '../layout/Cell';
import './Block.css';
import './LastBlock.css';
import blueHand from '../assets/welcome/blueHand.svg'

const LastBlock = (props) => {
    const logo = useRef();
    const [animationLogoKey, setAnimationLogoKey] = useState({});
    const [animationTaglineKey, setAnimationTaglineKey] = useState({});

    useLayoutEffect(() => {
        const logoCurrent = logo.current;
        const observer = new IntersectionObserver(
            ([entry]) => {
                setAnimationLogoKey(
                    entry.isIntersecting
                        ? {animationName: 'last-block-logo-zoom-in'}
                        : {animationName: 'none'}
                );
                setAnimationTaglineKey(
                    entry.isIntersecting
                        ? {animationName: 'last-block-tagline-zoom-in'}
                        : {animationName: 'none'}
                );
            }, {}
        );
        observer.observe(logoCurrent);
        return () => observer.unobserve(logoCurrent); 
    }, [logo]);
    return (
        <Row>
            <Cell className = "-whole -center last-block-container">
                <h2 className = 'block-title'
                    dangerouslySetInnerHTML = {{__html: props.title}}
                />
                <img className = "last-block-logo"
                    src = {blueHand}
                    alt = "Kaller Logo"
                    ref = {logo}
                    style = {animationLogoKey}
                />
                <h3 className = "last-block-tagline"
                    dangerouslySetInnerHTML = {{__html: props.text}}
                    style = {animationTaglineKey}
                />
            </Cell>
        </Row>
    );
}

export default LastBlock;