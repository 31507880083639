import React, { useState, useEffect } from 'react';
import './Footer.css';
import footerJson from '../files/text/footer.json'

const Footer = () => {
    const [footer, setFooter] = useState({});
    useEffect(() => { 
        (async () => {
            const json = footerJson[localStorage.getItem('SELECTED_LANGUAGE')] || footerJson['en']
            setFooter(json);
        })();
    }, [localStorage.getItem('SELECTED_LANGUAGE')]);
    return (
        <footer role="contentinfo">
            { footer.title && <div className="footer-copyright">{footer.title}</div> }
            { footer.socials && <div className="footer-socials"> {
                footer.socials.map((social, index) => {
                    return (
                        <div style={{width: 'auto', textAlign: 'center'}}>
                            <a href={social.url} key={social.title}>{social.title}</a>
                        </div>
                    );
                })
            } </div> }
        </footer>
    );
}

export default Footer;