import React from 'react';
import './FeaturesBlock.css';
import RowWithTwoNews from '../components/RowWithTwoNews';

const FeaturesBlock = (props) => {
    return (
        <section className="features-block-container">
            {props.header && <h2 className="-center">{props.header}</h2>}
            {props.blocks && props.blocks.map((row, index) => 
                <RowWithTwoNews cells={row} key={'fetures-row-' + index} />
            )}
        </section>
    );
}

export default FeaturesBlock;