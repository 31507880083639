import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Content from "../core/Content";
import Article from "../components/Article";
import Toon from "../components/Toon";
import AdditionalFeed from "../components/AdditionalFeed";
import FeaturesBlock from "../components/FeaturesBlock";
import JoinUsBlock from "../components/JoinUsBlock";
import './Features.css'

const Features = () => {
  const { alias } = useParams();
  const [content, setContent] = useState();
  const [additionalBlock, setAdditionaBlock] = useState();
  const [features, setFeatures] = useState();
  const [joinUs, setJoinUs] = useState(null);
  const [isToon, setIsToon] = useState(false);
  const articleContainer = useRef(null);
  const pageContent = new Content();
  const isMobile = pageContent.isMobile;
  const isTablet = pageContent.isTablet;
  const featureAlias = alias ? alias : pageContent.defaultFeature;
  const [title, setTitle] = useState(pageContent.defaultTitle);

  useEffect(() => {
    const content = new Content();
    (async () => {
      const response = await content.getFeature(featureAlias);
      setTitle(content.removeBreakLine(response.title));
      setContent(response);
      response.body ? setIsToon(false) : setIsToon(true);
    })();
    (async () => {
      const response = await content.getNews();
      setAdditionaBlock(response);
    })();
    (async () => {
      const response = await content.getFeatures();
      let flatBlocks = response.reduce((result, element) => {
        if (element.alias !== "/" + featureAlias) {
          element.path = content.featuresLink + element.alias;
          result.push(element);
        }
        return result;
      }, []);
      flatBlocks = content.splitRows(flatBlocks);
      setFeatures(flatBlocks);
    })();
    (async () => {
      const response = await content.getJoinUs();
      setJoinUs(response);
    })();
  }, [featureAlias]);

  useEffect(() => {
    document.title = title;
  }, [title]);


  return (
    <>
      <article ref={articleContainer} className={'features'}>
        {content &&
          (isToon ? (
            <Toon
              title={content.title}
              banner={content.banner}
              gallery={content.gallery}
              animation={content.animation}
              parent={articleContainer}
            />
          ) : (
            <Article
              title={content.title}
              leed={content.leed ? content.leed : false}
              banner={content.banner}
              gallery={content.gallery}
              body={content.body}
              animation={content.animation}
              parent={articleContainer}
            />
          ))}
        {additionalBlock && (
          <AdditionalFeed content={additionalBlock} parent={articleContainer} />
        )}
      </article>
      {joinUs && <JoinUsBlock body={joinUs.body} />}
      <section className="-main-column">
        {features && content && (
          <FeaturesBlock header={content.header} blocks={features} />
        )}
      </section>
    </>
  );
};

export default Features;
