import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useMemo,
  useLayoutEffect,
} from "react";
import Pager from "./Pager";
import Spinner from "./Spinner";
import Content from "../core/Content";
import "./Gallery.css";
import chevronRight from "../assets/gallery/chevron_right.svg";
import chevronLeft from "../assets/gallery/chevron_left.svg";
import close from "../assets/gallery/close.svg";
import plug from "../assets/gallery/plug.png";
import muteButtonSvg from "../assets/mute.svg";
import unmuteButtonSvg from "../assets/unmute.svg";

export function Gallery({ isOpen, onClose, gallery }) {
  const frame1 = useRef(null);
  const muteButton1 = useRef(null);
  const frame2 = useRef(null);
  const muteButton2 = useRef(null);

  //Video mute button state
  const [muteButtonSrc, setMuteButtonSrc] = useState(muteButtonSvg);

  //First frame animation classes
  const [firstFrame, setFirstFrame] = useState("");

  //Second frame animation classes
  const [secondFrame, setSecondFrame] = useState("gallery-set-to-right");

  // Dispalyed frame. First == 1, second == 2
  const [activeFrame, setActiveFrame] = useState(1);

  //Image index in first frame
  const [firstFrameImg, setFirstFrameImg] = useState(0);

  //Image index in second frame
  const [secondFrameImg, setSecondFrameImg] = useState(1);

  //Currently displayed image index
  const [activeImg, setActiveImg] = useState(0);

  //Loading progress indicator visibility class
  const [showSpinner, setSpinner] = useState("");

  //List of successfully loaded images
  const [isLoaded, setIsLoaded] = useState([]);

  //Gestures
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const minSwipeDistance = 50;

  //Slider buttons and pager visibility
  const isSlider = gallery.length > 1 ? true : false;

  //Check if first frame contains video to show or hide initial mute button
  const content = new Content();
  const isVideo = content.getExtension(gallery[0]) === "mp4" ? true : false;

  //Gallery images
  const images = useMemo(() => {
    const content = new Content();

    //Mark image as loaded
    const imageLoadHandler = (e, index) => {
      setIsLoaded((arr) => {
        return arr.map((current, j) => {
          return index === j ? true : current;
        });
      });
      if (activeImg === index) setSpinner("-hidden");
    };

    //Push images to gallery
    const images = [];
    for (let i = 0; i < gallery.length; i++) {
      let extension = content.getExtension(gallery[i]);
      if (extension === "jpg" || extension === "png" || extension === "jpeg") {
        images.push(
          <img
            src={gallery[i]}
            alt=""
            className="gallery-img"
            loading="lazy"
            onLoad={(e) => imageLoadHandler(e, i)}
            onClick={(e) => e.stopPropagation()}
          />
        );
      } else if (extension === "mp4") {
        images.push(
          <video
            src={gallery[i]}
            autoPlay
            playsInline
            loop
            onPlaying={(e) => imageLoadHandler(e, i)}
            muted={true}
            className="gallery-img"
          />
        );
      }
    }
    return images;
  }, [gallery, activeImg]);

  useEffect(() => {
    if (!isOpen) return;
    document.body.style.overflow = "hidden";
    document.documentElement.style.overflow = "hidden";

    //Safari click unmute button propagation first usage 
    frame2.current.style.display = 'none';

    //Mark all images as not loaded
    var loaded = [];
    for (let i = 0; i < gallery.length; i++) {
      loaded.push(false);
    }
    setIsLoaded(loaded);

    const muteVideo = (e) => {
      e.stopPropagation();
      let video = e.target;
      if (video.tagName !== "VIDEO") {
        video = video.parentElement.lastChild;
      }
      if (video === null || video.muted === undefined) return; 
      video.muted = !video.muted;
      video.muted
        ? setMuteButtonSrc(muteButtonSvg)
        : setMuteButtonSrc(unmuteButtonSvg);
    };

    const video1 = frame1.current;
    const video2 = frame2.current;
    video1.addEventListener("click", muteVideo);
    video2.addEventListener("click", muteVideo);

    return () => {
      document.body.style.overflow = "";
      document.documentElement.style.overflow = "";
      video1.removeEventListener("click", muteVideo);
      video2.removeEventListener("click", muteVideo);
    };
  }, [gallery, isOpen]);

  useLayoutEffect(() => {
    //Play video in visible slide, pause in invisible
    const playVideo = (frame) => {
      const el = frame.current.lastChild;
      if (el.tagName !== "VIDEO") return;
      setMuteButtonSrc(muteButtonSvg);
      const cls = frame.current.classList;
      if (
        cls.contains("gallery-hide-to-left") ||
        cls.contains("gallery-hide-to-right")
      ) {
        el.muted = true;
        el.pause();
      } else {
        var playPromise = el.play();
        if (playPromise !== undefined) {
          playPromise
            .then((_) => {
              if (document.body.contains(el)) {
                el.play();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    };

    //Show a play button if the frame contains video
    const showMuteButton = (el, buttonId) => {
      const button = document.getElementById(buttonId);
      if (el.tagName === "VIDEO") {
        button.style.display = "block";
      } else {
        button.style.display = "none";
      }
    };

    //Frame1 mutation callback
    const callback1 = () => {
      const el = frame1.current.lastChild;
      showMuteButton(el, "gallery-play-button1");
      playVideo(frame1);
    };

    //Frame2 mutation callback
    const callback2 = () => {
      const el = frame2.current.lastChild;
      showMuteButton(el, "gallery-play-button2");
      playVideo(frame2);
    };

    const config = { attributes: true };
    const frame1Observer = new MutationObserver(callback1);
    const frame2Observer = new MutationObserver(callback2);
    if (isOpen) {
      frame1Observer.observe(frame1.current, config);
      frame2Observer.observe(frame2.current, config);
    }

    return () => {
      if (isOpen) {
        frame1Observer.disconnect();
        frame2Observer.disconnect();
      }
    };
  }, [frame1, frame2, isOpen]);

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => {
    if (e.targetTouches[0].clientX === undefined) return;
    if (images.length === 1) return;
    var x = e.targetTouches[0].clientX;
    var distance = Math.round(x - touchStart);
    if (activeFrame === 1) {
      setFirstFrame("");
      frame1.current.style.transform = `translateX(${distance}px)`;
    } else {
      setSecondFrame("");
      frame2.current.style.transform = `translateX(${distance}px)`;
    }
    setTouchEnd(x);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      hideToLeft();
      return;
    }
    if (isRightSwipe) {
      hideToRight();
      return;
    }
    frame1.current.style.transform = `translateX(0)`;
    frame2.current.style.transform = `translateX(0)`;
  };

  const hideToLeft = (e) => {
    if (e !== undefined) {
      e.stopPropagation();
    }
    var active;
    if (activeFrame === 1) {
      //get next image
      active = getRightImg(firstFrameImg);

      //set next image to next slide
      setSecondFrameImg(active);
      setFirstFrame("gallery-hide-to-left ");
      frame2.current.style.display = 'block';
      setSecondFrame("gallery-show-from-right ");

      //mark active frame
      setActiveFrame(2);
    } else {
      active = getRightImg(secondFrameImg);
      setFirstFrameImg(active);
      setFirstFrame("gallery-show-from-right ");
      setSecondFrame("gallery-hide-to-left ");
      setActiveFrame(1);
    }
    const spinner = isLoaded[active] ? "-hidden" : "";
    setSpinner(spinner);
    setActiveImg(active);
  };

  const hideToRight = (e) => {
    if (e !== undefined) {
      e.stopPropagation();
    }
    var active;
    if (activeFrame === 1) {
      active = getLeftImg(firstFrameImg);
      setSecondFrameImg(active);
      setFirstFrame("gallery-hide-to-right ");
      setSecondFrame("gallery-show-from-left ");
      frame2.current.style.display = 'block';
      setActiveFrame(2);
    } else {
      active = getLeftImg(secondFrameImg);
      setFirstFrameImg(active);
      setFirstFrame("gallery-show-from-left ");
      setSecondFrame("gallery-hide-to-right ");
      setActiveFrame(1);
    }
    const spinner = isLoaded[active] ? "-hidden" : "";
    setSpinner(spinner);
    setActiveImg(active);
  };

  const hideGallery = () => {
    setFirstFrame("");
    setSecondFrame("gallery-set-to-right");
    setFirstFrameImg(0);
    setSecondFrameImg(0);
    setActiveImg(0);
    onClose();
  };

  const getLeftImg = (img) => {
    if (img === 0) {
      return images.length - 1;
    }
    return img - 1;
  };

  const getRightImg = (img) => {
    if (img === images.length - 1) {
      return 0;
    }
    return img + 1;
  };

  return (
    <Fragment>
      {isOpen && (
        <div className="gallery">
          <div className="gallery-overlay" onClick={hideGallery} />
          <div
            className="gallery-container"
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
          >
            <img
              src={close}
              alt="Close Button"
              className="gallery-button gallery-close"
              onClick={hideGallery}
            />
            {isSlider && (
              <img
                src={chevronLeft}
                alt="Slide Backward"
                className="gallery-button gallery-left-button"
                onClick={(e) => hideToRight(e)}
              />
            )}
            {isSlider && (
              <img
                src={chevronRight}
                alt="Slide Forward"
                className="gallery-button gallery-right-button"
                onClick={(e) => hideToLeft(e)}
              />
            )}
            {isSlider && <Pager count={images.length} active={activeImg} />}
            <img src={plug} alt="decoration" className="gallery-plug" />
            <div ref={frame1} className={"gallery-img-container " + firstFrame}>
              {images[firstFrameImg] && (
                <>
                  <div
                    className={`gallery-img-loading ${
                      firstFrameImg === activeImg ? showSpinner : "-hidden"
                    }`}
                  >
                    <Spinner />
                  </div>
                  <img
                    src={muteButtonSrc}
                    id="gallery-play-button1"
                    alt="Mute button"
                    className={`gallery-mute-button ${
                      isVideo ? "" : "-hidden"
                    }`}
                    ref={muteButton1}
                  />
                  {images[firstFrameImg]}
                </>
              )}
            </div>
            {images[secondFrameImg] && (
              <div
                ref={frame2}
                className={"gallery-img-container " + secondFrame}
              >
                <div
                  className={`gallery-img-loading ${
                    secondFrameImg === activeImg ? showSpinner : "-hidden"
                  }`}
                >
                  <Spinner />
                </div>
                <img
                  ref={muteButton2}
                  src={muteButtonSrc}
                  id="gallery-play-button2"
                  alt="play button"
                  className={`gallery-mute-button ${isVideo ? "" : "-hidden"}`}
                />
                {images[secondFrameImg]}
              </div>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Gallery;
